import 'quasar/dist/quasar.css'
import '@quasar/extras/material-icons/material-icons.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet-geosearch/assets/css/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

import { createApp } from 'vue'
import App from './App.vue'
import i18n from './../../i18n'
import services from './services'
import FormBuilder from './../../packages/form-builder'
import { createRouter, createWebHashHistory } from 'vue-router'
import Quasar from 'quasar/src/vue-plugin.js';import Meta from 'quasar/src/plugins/meta/Meta.js';import LoadingBar from 'quasar/src/plugins/loading-bar/LoadingBar.js';import Cookies from 'quasar/src/plugins/cookies/Cookies.js';

export function bootstrap (root, eventBus, options) {
  const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: []
  })

  const app = createApp(App)
  .use(Quasar, {
    config: {
      animations: 'all'
    },
    plugins: {
      Meta,
      LoadingBar,
      Cookies
    }
  })
  .use(router)
  .use(FormBuilder)
  .use(i18n)

  app.config.globalProperties.$service = services
  app.config.globalProperties.$appOptions = options.appOptions

  return app
}
